import React from 'react'
import styles from './AddToWishlist.module.scss'
import Checkbox from '@material-ui/core/Checkbox'
import Favorite from '@material-ui/icons/Favorite'
import FavoriteBorder from '@material-ui/icons/FavoriteBorder'
import IconButton from '@material-ui/core/IconButton'

type AddToWishlistType = {
   checked: boolean
   toggleCheckbox: any
}

const AddToWishlist: React.FC<AddToWishlistType> = ({ checked, toggleCheckbox }) => {
   return (
      <IconButton size='small' className={styles.button} disableRipple>
         <Checkbox
            icon={<FavoriteBorder color="primary" />}
            checkedIcon={<Favorite />}
            checked={checked}
            size="small"
            onChange={toggleCheckbox}
            name="checkedHeart"
            color="primary"
         />
      </IconButton>
   )
}

export default AddToWishlist
